.blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 100px;
  }
  
  .image-container {
    width: 100%;
    height: 300px;
    background: url('../assets/images/blog.png') no-repeat center center;
    background-size: cover;
    border-radius: 16px 16px 0 0;
  }
  
  .main-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 100%;
    border-radius: 0px 0px 16px 16px;
    background: #FFF;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.20);
    padding: 20px;
    margin-top: 100px;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    gap: 16px;
    border-radius: 0px 0px 16px 16px;
    background: #FFF;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.20);
    width: calc(33.333% - 33.333px); /* Ensures three cards per row with a gap */
    max-width: 400px; /* Optional max-width to avoid too wide cards */
  }
  
  .upper-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 24px 23.188px 15px 24px;
  }
  
  .typography {
    color: #000;
    font-family: "Plus Jakarta Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 181.818% */
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  
  .heading {
    color: #000;
    font-family: "Plus Jakarta Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 133.333% */
    letter-spacing: -0.2px;
  }
  
  .lower-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  
  .card-image {
    width: 100%;
    border-radius: 0px 0px 16px 16px;
  }
  