.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px;
  margin: 0 auto;
  margin-top: 10%; /* Default margin for all screens */
  box-sizing: border-box;
}

/* Margin adjustment for big screens */
@media (min-width: 1200px) {
  .section-container {
    margin-top: 2%; /* Reduced margin for larger screens */
  }
}
.heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 16px;
  margin: 0 auto;
  
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.main-heading {
  color: #101828;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
}

.highlight {
  color: #E63F31;
  font-weight: 600;
}
.high{
  color: var(--Gray-600, #475467);
font-family: "Plus Jakarta Sans";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
}
.content-text {
  color: #475467;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

/* Big Container Styles */
.big-container {
  display: flex;
  width: 100%;
  max-width: 1400px;
  padding: 20px 16px;
  margin: 0 auto;
  align-items: stretch; 
  gap: 30px;
  flex-wrap: wrap;
  box-sizing: border-box;
}

/* Left Container */
.left-container {
  display: flex;
  flex-direction: column;
  gap: 12px; 
  flex: 1;
  min-width: 300px;
  padding: 16px;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
}
.heading-box{
 
  display: inline-flex;
align-items: center;
gap: 12px;
}

.heading-svg{
  width: 48px;
height: 48px;
}

.check-icon{
  width: 28px;
height: 28px;
}

.text-max{
  display: flex;
align-items: flex-start;
gap: 12px;
align-self: stretch;
}

.sub-text{
  color: var(--Gray-600, #475467);
font-family: "Plus Jakarta Sans";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px;
text-align: justify; 
}
.right-container {
  flex: 1;
  min-width: 300px;
  border-radius: 8px;
  display: flex; /* Ensures the image aligns properly */
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  overflow: hidden; /* Prevent image overflow */
}
.right-container img {
  max-width: 100%; /* Ensures the image scales down properly */
  height: auto; /* Maintains aspect ratio */
  border-radius: 8px; /* Matches the container's border-radius */
  object-fit: cover; /* Ensures the image covers the space appropriately */
}

@media (min-width: 1200px) {
  .heading-container {
    margin-top: 10%; /* Adjust the margin as needed for spacing */
  }
}
@media (min-width: 768px) {
  .big-container {
    flex-wrap: nowrap;
  }
}
@media (max-width: 768px) {
  .right-container {
    min-width: 100%; /* Make the container span the full width on smaller screens */
    padding: 16px; /* Add some padding for better spacing */
  }
}

@media (max-width: 480px) {
  .right-container {
    padding: 8px; /* Reduce padding for very small screens */
  }

  .right-container img {
    border-radius: 4px; /* Slightly reduce border-radius for small screens */
  }
}

@media (max-width: 768px) {
  .sub-text {
    font-size: 16px; /* Slightly smaller font for tablets */
    line-height: 26px; /* Adjust line height for better readability */
    padding: 8px; /* Add some padding for spacing */
  }
}

@media (max-width: 480px) {
  .sub-text {
    font-size: 14px; /* Smaller font size for mobile */
    line-height: 24px; /* Adjust line height accordingly */
    padding: 4px; /* Minimize padding for compact screens */
  }
}