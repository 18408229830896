/* QuoteList.css */

/* Container for the quotes */
.quote-list-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Roboto', sans-serif;
    background-color: #f4f7fc;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    animation: fadeIn 1s ease-in-out;
  }
  
  /* Heading */
  .quote-list-container h1 {
    text-align: center;
    color: #3a506b;
    margin-bottom: 40px;
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    animation: slideInDown 0.8s ease-out;
  }
  
  /* Individual quote items */
  .quote-list-item {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s ease forwards;
  }
  
  .quote-list-item:hover {
    transform: translateY(-8px) scale(1.02);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    background-color: #f0f8ff;
  }
  
  /* Headings for each quote */
  .quote-list-item h3 {
    margin: 0;
    font-size: 1.8rem;
    color: #007BFF;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: capitalize;
    transition: color 0.3s ease;
  }
  
  .quote-list-item h3:hover {
    color: #0056b3;
  }
  
  /* Paragraphs inside the quote item */
  .quote-list-item p {
    margin: 8px 0;
    color: #555;
    font-size: 1.1rem;
    line-height: 1.6;
    transition: color 0.3s ease;
  }
  
  .quote-list-item p:hover {
    color: #333;
  }
  
  /* Bold label for specific fields */
  .quote-list-item p span {
    font-weight: 600;
    color: #333;
    display: inline-block;
    min-width: 150px;
    text-transform: uppercase;
    font-size: 1.05rem;
    letter-spacing: 0.5px;
  }
  
  /* Loading and error messages */
  .loading-message,
  .error-message {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 50px;
    color: #ff4a4a;
  }
  
  .loading-message {
    color: #007BFF;
  }
  
  /* List style reset */
  ul {
    list-style: none;
    padding: 0;
  }
  
  /* Fade-in animation for the container */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Slide down for title heading */
  @keyframes slideInDown {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* Fade-in and upward animation for each quote item */
  @keyframes fadeInUp {
    from {
      transform: translateY(30px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  