/* Container Styling */
.contacts-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 30px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  /* Heading Styling */
  .contacts-container h2 {
    text-align: center;
    color: #444;
    font-size: 28px;
    margin-bottom: 30px;
    font-weight: bold;
    letter-spacing: 1px;
  }
  
  /* List Styling */
  .contacts-list {
    list-style-type: none;
    padding: 0;
  }
  
  /* Contact Item Styling */
  .contact-item {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    border-left: 5px solid #0073e6;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .contact-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  /* Header Styling */
  .contact-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  /* Contact Name Styling */
  .contact-name {
    font-size: 20px;
    font-weight: bold;
    color: #0073e6;
  }
  
  /* Contact Email Styling */
  .contact-email {
    font-size: 14px;
    color: #666;
  }
  
  /* Contact Phone Styling */
  .contact-phone {
    font-size: 16px;
    color: #333;
    margin: 5px 0;
  }
  
.contact-company{
  font-size: 16px;
  color: #333;
}




  /* Contact Message Styling */
  .contact-message {
    font-size: 15px;
    color: #555;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 8px;
    font-style: italic;
  }
  
  /* Loading and Error Styling */
  .loading, .error {
    text-align: center;
    color: #0073e6;
    font-size: 18px;
    margin-top: 20px;
  }
  