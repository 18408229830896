.navtool-container {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    box-sizing: border-box;
    background-color: #f8f9fa;
  }
  
  .logo {
    width: 150px;
    height: 64.285px;
    flex-shrink: 0;

  }
  
  .nav-buttons {
    display: inline-flex;
    align-items: flex-start;
    gap: 60px;
    margin-left: 76px;
  }
  
  .nav-buttons button {
    background: none;
    border: none;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 175.7%; /* 38.654px */
    color: #213757;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .nav-buttons button:hover {
    color: #007bff;
  }
  
  .cta-container {
    display: inline-flex;
    height: 43.921px;
    padding: 8px 15px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    background-color: #007bff;
    color: white;
    border-radius: 8px;
    margin-top: 10px;
  }
  
  .cta-container button {
    background: none;
    border: none;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 175.7%; /* 38.654px */
    color: white;
    cursor: pointer;
  }
  