@keyframes slideInUp {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeInUp {
    opacity: 0; /* Start hidden, then become visible with animation */
    animation: slideInUp 1.2s forwards;
  }
  