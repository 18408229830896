.work-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  .work-form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .work-form input[type="text"],
  .work-form textarea,
  .work-form input[type="file"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .work-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .work-form button:hover {
    background-color: #0056b3;
  }
  
  .work-post {
    text-align: center;
  }
  
  .work-post img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .work-post button {
    padding: 10px 20px;
    margin: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .work-post button.delete {
    background-color: #dc3545;
  }
  
  .work-post button:hover {
    background-color: #0056b3;
  }
  
  .work-post button.delete:hover {
    background-color: #c82333;
  }
  