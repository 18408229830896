.section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 16px;
    margin: 0 auto;
    margin-top: 10%; /* Default margin for all screens */
    box-sizing: border-box;
  }
  
  /* Margin adjustment for big screens */
  @media (min-width: 1200px) {
    .section-container {
      margin-top: 4%; /* Reduced margin for larger screens */
    }
  }
form.emp {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

form input {
    width: calc(100% - 20px);
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

form button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

form button:hover {
    background-color: #0056b3;
}
